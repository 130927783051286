/* eslint-disable @typescript-eslint/no-redeclare */

/**
 * 通用埋点事件列表
 *
 * https://liaofeng.feishu.cn/docx/OJIzdT18xoPgvtx5d0JcjHxNnCe
 */
//#region 基础模块
export const enum TrackEvent {
  /**
   * 登入
   */
  login = 'login',
  /**
   * 用户点击安全退出
   */
  logoutClick = 'logoutClick',
  /**
   * 因身份认证失败而导致的无法获取身份信息，此时会自动退出（刷新页面或者首次打开页面时出现）
   */
  authenticationFailedLogout1 = 'authenticationFailedLogout1',
  /**
   * 因身份认证失败而导致的接口请求失败，此时会自动退出（使用过程中出现）
   */
  authenticationFailedLogout2 = 'authenticationFailedLogout2',
  /**
   * 推出
   */
  logout = 'logout',
  /**
   * 页面跳转
   */
  location = 'location',
  /**
   * 页面事件
   */
  pageView = 'pageView',
  /**
   * XHR 耗时信息
   */
  xhrTimings = 'xhrTimings',
}
//#endregion

//#region 商品模块
//#region SKU
export const enum TrackEvent {
  /**
   * SKU 导出
   */
  skuExport = 'skuExport',
  /**
   * 图片导出
   */
  skuImageExport = 'skuImageExport',
  /**
   * SKU 查询
   */
  skuListQuery = 'skuListQuery',
  /**
   * 新增商品
   */
  skuCreate = 'skuCreate',
  /**
   * 编辑商品
   */
  skuUpdate = 'skuUpdate',
  /**
   * 商品备注
   */
  skuRemark = 'skuRemark',
  /**
   * 启用/停用商品
   */
  skuStatusChange = 'skuStatusChange',
  /**
   * SKU 合并
   */
  skuMerge = 'skuMerge',

  //#region 商品批量操作
  /**
   * 批量修改商品基础信息
   */
  skuBatchUpdateBasicInfo = 'skuBatchUpdateBasicInfo',
  /**
   * 批量修改商品申报信息
   */
  skuBatchUpdateDeclaration = 'skuBatchUpdateDeclaration',
  /**
   * 批量合并商品
   */
  skuBatchMerge = 'skuBatchMerge',
  /**
   * 批量添加商品自定义分类
   */
  skuBatchAddCustomCategories = 'skuBatchAddCustomCategories',
  /**
   * 批量移除商品自定义分类
   */
  skuBatchRemoveCustomCategories = 'skuBatchRemoveCustomCategories',
  /**
   * 批量修改违规原因
   */
  skuBatchUpdateViolationReason = 'skuBatchUpdateViolationReason',
  /**
   * 批量修改物流属性
   */
  skuBatchUpdateLogisticsAttribute = 'skuBatchUpdateLogisticsAttribute',
  //#endregion

  /**
   * 虚拟SKU变更关联
   */
  vskuUpdateLinkedSku = 'vskuUpdateLinkedSku',
}
//#endregion

//#region SPU
export const enum TrackEvent {
  /**
   * SPU 导出
   */
  spuExport = 'spuExport',
  /**
   * SPU 查询
   */
  spuListQuery = 'spuListQuery',
  /**
   * SPU 新增
   */
  spuCreate = 'spuCreate',
  /**
   * SPU 编辑
   */
  spuUpdate = 'spuUpdate',
  /**
   * 关联商品SKU
   */
  spuLinkSku = 'spuLinkSku',
}
//#endregion

//#region 图片中心
export const enum TrackEvent {
  /**
   * 图片中心上传
   */
  imageUpload = 'imageUpload',
}
//#endregion

//#region 库存查询
export const enum TrackEvent {
  /**
   * 库存查询
   */
  skuInventoryListQuery = 'skuInventoryListQuery',
  /**
   * 马帮库存迁移
   */
  skuInventoryMigrate = 'skuInventoryMigrate',
  /**
   * 库存迁移历史导出
   */
  skuInventoryMigrationHistory = 'skuInventoryMigrationHistory',
}
//#endregion
//#endregion

//#region 订单模块
export const enum TrackEvent {
  /**
   * 订单查询
   */
  salesOrderListQuery = 'salesOrderListQuery',
  /**
   * 新增订单
   */
  salesOrderCreate = 'salesOrderCreate',
  /**
   * 作废订单
   */
  salesOrderInvalidate = 'salesOrderInvalidate',
  /**
   * 导出PDF面单
   */
  salesOrderExportBillway = 'salesOrderExportBillway',
  /**
   * 订单导出
   */
  salesOrderExport = 'salesOrderExport',
  /**
   * 订单导入
   */
  salesOrderImport = 'salesOrderImport',
  /**
   * 查询可合并订单
   */
  mergeableSalesOrderQuery = 'mergeableSalesOrderQuery',
  /**
   * 交运异常查询
   */
  salesOrderDeliveryFailedQuery = 'salesOrderDeliveryFailedQuery',
}

//#region 订单批量操作
export const enum TrackEvent {
  /**
   * 批量合并订单
   */
  salesOrderBatchMerge = 'salesOrderBatchMerge',
  /**
   * 批量更新订单信息
   */
  salesOrderBatchUpdate = 'salesOrderBatchUpdate',
  /**
   * 批量全部转入交运订单
   *
   * 包括勾选和按查询条件
   */
  salesOrderBatchDelivery = 'salesOrderBatchDelivery',
  /**
   * 批量拆分订单
   */
  salesOrderBatchSplit = 'salesOrderBatchSplit',
  /**
   * 批量将订单交运到指定的物流渠道
   */
  salesOrderBatchDeliver = 'salesOrderBatchDeliver',
  /**
   * 批量将指定的订单从交运异常中移除
   */
  deliveryFailedOrderBatchRemove = 'deliveryFailedOrderBatchRemove',
  /**
   * 批量重发
   */
  salesOrderBatchReship = 'salesOrderBatchReship',
  /**
   * 批量作废订单
   */
  salesOrderBatchInvalidate = 'salesOrderBatchInvalidate',
  /**
   * 批量激活订单
   */
  salesOrderBatchActive = 'salesOrderBatchActive',
  /**
   * 批量更新订单商品仓库
   */
  salesOrderBatchUpdateWarehouse = 'salesOrderBatchUpdateWarehouse',
  /**
   * 批量更新订单商品
   */
  salesOrderBatchUpdateItems = 'salesOrderBatchUpdateItems',
  /**
   * 批量作废订单商品
   */
  salesOrderBatchInvalidteItems = 'salesOrderBatchInvalidteItems',
  /**
   * 批量增加订单商品
   */
  salesOrderBatchAddItems = 'salesOrderBatchAddItems',
  /**
   * 批量获取订单申报列表
   */
  salesOrderBatchGetDeclaration = 'salesOrderBatchGetDeclaration',
  /**
   * 批量更新订单申报信息
   */
  salesOrderBatchUpdateDeclaration = 'salesOrderBatchUpdateDeclaration',
  /**
   * 批量锁定库存
   */
  salesOrderBatchLockInventory = 'salesOrderBatchLockInventory',
  /**
   * 批量取消锁定库存
   */
  salesOrderBatchUnlockInventory = 'salesOrderBatchUnlockInventory',
  /**
   * 通过手动输入来批量更新交运信息
   */
  salesOrderBatchUpdateDeliveryInfoByInput = 'salesOrderBatchUpdateDeliveryInfoByInput',
  /**
   * 通过手动输入来批量更新交运信息
   */
  salesOrderBatchUpdateDeliveryInfoByFile = 'salesOrderBatchUpdateDeliveryInfoByFile',
  /**
   * 通过手动输入来批量更新客户信息
   */
  salesOrderBatchUpdateCustomerInfoByInput = 'salesOrderBatchUpdateCustomerInfoByInput',
  /**
   * 通过手动输入来批量更新客户信息
   */
  salesOrderBatchUpdateCustomerInfoByFile = 'salesOrderBatchUpdateCustomerInfoByFile',
  /**
   * 批量为订单添加正常分类
   */
  salesOrderBatchAddCategories = 'salesOrderBatchAddCategories',
  /**
   * 批量为订单移除正常分类
   */
  salesOrderBatchRemoveCategories = 'salesOrderBatchRemoveCategories',
  /**
   * 批量为订单添加异常分类
   */
  salesOrderBatchAddOrdersAnomalousCategories = 'salesOrderBatchAddOrdersAnomalousCategories',
  /**
   * 批量为订单移除异常分类
   */
  salesOrderBatchRemoveOrdersAnomalousCategories = 'salesOrderBatchRemoveOrdersAnomalousCategories',
  /**
   * 批量处理-更换为同一商品
   */
  salesOrderBatchReplaceToSameItems = 'salesOrderBatchReplaceToSameItems',
  /**
   * 批量处理-指定sku数量更换
   */
  salesOrderBatchReplaceSkuWithQuantity = 'salesOrderBatchReplaceSkuWithQuantity',
  /**
   * 批量拆分成单品订单
   */
  salesOrderBatchSplitToSingleSkuOrder = 'salesOrderBatchSplitToSingleSkuOrder',
  /**
   * 批量拆分成单品单量订单
   */
  salesOrderBatchSplitToSingleSkuOnlyOneOrder = 'salesOrderBatchSplitToSingleSkuOnlyOneOrder',
  /**
   * 批量拆分指定订单中缺货的商品
   */
  salesOrderBatchSplitOutOfStockOrders = 'salesOrderBatchSplitOutOfStockOrders',
  /**
   * 按给定的物流属性批量拆分指定订单
   */
  salesOrderBatchSplitOrdersByLogisticsAttribute = 'salesOrderBatchSplitOrdersByLogisticsAttribute',
  /**
   * 按 SKU 批量拆分指定订单
   */
  salesOrderBatchSplitBySku = 'salesOrderBatchSplitBySku',
  /**
   * 批量取消标记不合并
   */
  salesOrderbBatchCancelNotMergeMark = 'salesOrderbBatchCancelNotMergeMark',
}
//#endregion

//#region 订制品审核
export const enum TrackEvent {
  /**
   * 订制品审核查询
   */
  customReviewListQuery = 'customReviewListQuery',
  /**
   * 订制品审核导出
   */
  customReviewExport = 'customReviewExport',
}
//#endregion

//#region 订单相关日志
//#region 订单交运日志
export const enum TrackEvent {
  /**
   * 订单交运日志查询
   */
  salesOrderDeliveryLogListQuery = 'salesOrderDeliveryLogListQuery',
  /**
   * 订单交运日志导出
   */
  salesOrderDeliveryLogExport = 'salesOrderDeliveryLogExport',
}
//#endregion
//#endregion

//#region 订单自动化规则
//#region 订单处理规则
export const enum TrackEvent {
  /**
   * 查询订单处理规则
   */
  salesOrderHandlingRuleListQuery = 'salesOrderHandlingRuleListQuery',
  /**
   * 新增订单处理规则
   */
  salesOrderHandlingRuleCreate = 'salesOrderHandlingRuleCreate',
  /**
   * 编辑订单处理规则
   */
  salesOrderHandlingRuleUpdate = 'salesOrderHandlingRuleUpdate',
  /**
   * 停用订单处理规则
   */
  salesOrderHandlingRuleDisable = 'salesOrderHandlingRuleDisable',
}
//#endregion

//#region 订单申报规则
export const enum TrackEvent {
  /**
   * 查询订单申报规则
   */
  salesOrderDeclarationRuleListQuery = 'salesOrderDeclarationRuleListQuery',
  /**
   * 新增订单申报规则
   */
  salesOrderDeclarationRuleCreate = 'salesOrderDeclarationRuleCreate',
  /**
   * 编辑订单申报规则
   */
  salesOrderDeclarationRuleUpdate = 'salesOrderDeclarationRuleUpdate',
  /**
   * 停用订单申报规则
   */
  salesOrderDeclarationRuleDisable = 'salesOrderDeclarationRuleDisable',
}
//#endregion

//#region 仓库匹配规则
export const enum TrackEvent {
  /**
   * 查询仓库匹配规则
   */
  warehouseMatchingRuleListQuery = 'warehouseMatchingRuleListQuery',
  /**
   * 新增仓库匹配规则
   */
  warehouseMatchingRuleCreate = 'warehouseMatchingRuleCreate',
  /**
   * 编辑仓库匹配规则
   */
  warehouseMatchingRuleUpdate = 'warehouseMatchingRuleUpdate',
  /**
   * 停用仓库匹配规则
   */
  warehouseMatchingRuleDisable = 'warehouseMatchingRuleDisable',
}
//#endregion

//#region 物流匹配规则
export const enum TrackEvent {
  /**
   * 查询物流匹配规则
   */
  logisticsMatchingRuleListQuery = 'logisticsMatchingRuleListQuery',
  /**
   * 新增物流匹配规则
   */
  logisticsMatchingRuleCreate = 'logisticsMatchingRuleCreate',
  /**
   * 编辑物流匹配规则
   */
  logisticsMatchingRuleUpdate = 'logisticsMatchingRuleUpdate',
  /**
   * 停用物流匹配规则
   */
  logisticsMatchingRuleDisable = 'logisticsMatchingRuleDisable',
}
//#endregion
//#endregion

//#endregion

//#region 客户管理
export const enum TrackEvent {
  /**
   * 客户列表查询
   */
  customerListQuery = 'customerListQuery',
  /**
   * 新增客户
   */
  customerCreate = 'customerCreate',
  /**
   * 编辑客户
   */
  customerUpdate = 'customerUpdate',
  /**
   * 客户导出
   */
  customerExport = 'customerExport',
  /**
   * 加入黑名单
   */
  customerBan = 'customerBan',
  /**
   * 移除黑名单
   */
  customerUnban = 'customerUnban',
  /**
   * 批量加入黑名单
   */
  customerBatchBan = 'customerBatchBan',
  /**
   * 批量移出黑名单
   */
  customerBatchUnban = 'customerBatchUnban',
}
//#endregion

//#region 供应链模块(采购订单)
export const enum TrackEvent {
  /**
   * 新增采购单(采购单列表)
   */
  purchasingOrderCreate = 'purchasingOrderCreate',
  /**
   * 新增采购单(缺货计划)
   */
  purchasingOrderCreateOutOfStock = 'purchasingOrderCreateOutOfStock',
  /**
   * 新增采购单(备货计划)
   */
  purchasingOrderCreateStocking = 'purchasingOrderCreateStocking',
  /**
   * 编辑采购单
   */
  purchasingOrderUpdate = 'purchasingOrderUpdate',
  /**
   * 调整采购明细
   */
  purchasingAdjustPurchasingDetails = 'purchasingAdjustPurchasingDetails',
  /**
   * 采购订单查询全部
   */
  purchasingListQuery = 'purchasingListQuery',
  /**
   * 采购订单查询 待合并
   */
  purchasingSearchToBeMerge = 'purchasingSearchToBeMerge',
  /**
   * 拆分采购单
   */
  purchasingSplitPurchaseOrder = 'purchasingSplitPurchaseOrder',
  /**
   * 合并采购单
   */
  purchasingConsolidatePurchaseOrders = 'purchasingConsolidatePurchaseOrders',
  /**
   * 采购订单作废
   */
  purchasingVoidOrder = 'purchasingVoidOrder',
  /**
   * 导出定制采购单
   */
  purchasingExportCustomPurchaseOrders = 'purchasingExportCustomPurchaseOrders',
  /**
   * 导出采购单
   */
  purchasingExportPurchaseOrder = 'purchasingExportPurchaseOrder',
  /**
   * 导出定制图片
   */
  purchasingExportCustomImages = 'purchasingExportCustomImages',
  /**
   * 导出标签
   */
  purchasingExportTags = 'purchasingExportTags',
  /**
   * 采购单导入
   */
  purchasingPurchaseOrderImport = 'purchasingPurchaseOrderImport',
  /**
   * 批量同步1688信息
   */
  purchasingBatchSynchronizationOf1688Information = 'purchasingBatchSynchronizationOf1688Information',
  /**
   * 批量更换采购员
   */
  purchasingBatchChangeUser = 'purchasingBatchChangeUser',
  /**
   * 批量作废采购单
   */
  purchasingBatchVoidOrder = 'purchasingBatchVoidOrder',
  /**
   * 批量参与在途计划
   */
  purchasingBatchParticipatePlan = 'purchasingBatchParticipatePlan',
  /**
   * 批量不参与在途计划
   */
  purchasingBatchNotParticipatePlan = 'purchasingBatchNotParticipatePlan',
  /**
   * 批量打回新订单
   */
  purchasingBatchReturnNewOrder = 'purchasingBatchReturnNewOrder',
  /**
   * 批量提交采购单
   */
  purchasingBatchSubmitOrder = 'purchasingBatchSubmitOrder',
  /**
   * 批量申请付款
   */
  purchasingBatchApplyPayment = 'purchasingBatchApplyPayment',
  /**
   * 批量申请撤回
   */
  purchasingBatchApplyWithdraw = 'purchasingBatchApplyWithdraw',
  /**
   * 批量修改自定义分类
   */
  purchasingBatchUpdateCustomCategories = 'purchasingBatchUpdateCustomCategories',
  /**
   * 批量1688下单
   */
  purchasingBatchPlace1688Order = 'purchasingBatchPlace1688Order',
  /**
   * 批量合并采购单
   */
  purchasingBatchMergeOrder = 'purchasingBatchMergeOrder',
}
//#endregion

//#region 供应链模块(智能计算)
export const enum TrackEvent {
  /**
   * 缺货计划查询
   */
  purchasingOutOfStockingListQuery = 'purchasingOutOfStockingListQuery',
  /**
   * 缺货计划编辑
   */
  purchasingOutOfStockingUpdate = 'purchasingOutOfStockingUpdate',
  /**
   * 缺货计划导出
   */
  purchasingOutOfStockingExport = 'purchasingOutOfStockingExport',
  /**
   * 缺货计划批量修改供应商
   */
  purchasingOutOfStockingBatchUpdateSupplier = 'purchasingOutOfStockingBatchUpdateSupplier',
  /**
   * 备货计划查询
   */
  purchasingStockingListQuery = 'purchasingStockingListQuery',
  /**
   * 备货计划编辑
   */
  purchasingStockingUpdate = 'purchasingStockingUpdate',
  /**
   * 备货计划删除
   */
  purchasingStockingRemove = 'purchasingStockingRemove',
  /**
   * 备货计划导出
   */
  purchasingStockingExport = 'purchasingStockingExport',
  /**
   * 备货计划批量删除
   */
  purchasingStockingBatchRemove = 'purchasingStockingBatchRemove',
  /**
   * 备货计划批量修改供应商
   */
  purchasingStockingBatchUpdateSupplier = 'purchasingStockingBatchUpdateSupplier',
}
//#endregion

//#region 供应链模块(退货退款)
export const enum TrackEvent {
  /**
   * 退货退款查询
   */
  purchasingRefundListQuery = 'purchasingRefundListQuery',
  /**
   * 新增退货退款
   */
  purchasingRefundCreate = 'purchasingRefundCreate',
  /**
   * 编辑辑退货退款
   */
  purchasingRefundUpdate = 'purchasingRefundUpdate',
  /**
   * 仓库处理
   */
  purchasingRefundWarehouseHandling = 'purchasingRefundWarehouseHandling',
  /**
   * 作废退货退款
   */
  purchasingRefundVoid = 'purchasingRefundVoid',
}
//#endregion

//#region 供应链模块(财务付款)
//#region 供应链模块(供应商)
export const enum TrackEvent {
  /**
   * 财务付款备注
   */
  purchasingPaymentRemark = 'purchasingPaymentRemark',
  /**
   * 财务付款导出
   */
  purchasingPaymentExport = 'purchasingPaymentExport',
  /**
   * 供应商查询
   */
  purchasingSupplierSearch = 'purchasingSupplierSearch',
  /**
   * 新增供应商
   */
  purchasingSupplierCreate = 'purchasingSupplierCreate',
  /**
   * 编辑供应商
   */
  purchasingSupplierUpdate = 'purchasingSupplierUpdate',
  /**
   * 删除供应商
   */
  purchasingSupplierDelete = 'purchasingSupplierDelete',
  /**
   * 停用供应商
   */
  purchasingSupplierDeactivate = ' purchasingSupplierDeactivate',
}
//#endregion

//#region 供应链模块(审核模块)
export const enum TrackEvent {
  /**
   * 采购审核查询
   */
  purchasingReviewListQuery = 'purchasingReviewListQuery',
  /**
   * 采购审核编辑
   */
  purchasingReviewListUpdate = 'purchasingReviewListUpdate',
  /**
   * 批量采购审核
   */
  purchasingBatchPurchaseReview = 'purchasingBatchPurchaseReview',
  /**
   * 付款审核查询
   */
  purchasingPaymentReviewListQuery = 'purchasingPaymentReviewListQuery',
  /**
   * 付款审核
   */
  purchasingPaymentReview = 'purchasingPaymentReview',
  /**
   * 采购审核历史
   */
  purchasingPurchaseReviewHistory = 'purchasingPurchaseReviewHistory',
  /**
   * 付款审核历史
   */
  purchasingPaymentReviewHistory = 'purchasingPaymentReviewHistory',
}
//#endregion

//#region 仓储模块(入库签收)
export const enum TrackEvent {
  /**
   * 完成签收
   */
  signature = 'signature',
  /**
   * 签收打印
   */
  signaturePrint = 'signaturePrint',
}
//#endregion

//#region 仓储模块(入库上架)
export const enum TrackEvent {
  /**
   * 一键上架
   */
  shelfBatch = 'shelfBatch',
  /**
   * 完成上架
   */
  shelfComplete = 'shelfComplete',
}
//#endregion

//#region 仓储模块(入库单)
export const enum TrackEvent {
  /**
   * 新增入库单
   */
  inboundOrderCreate = 'inboundOrderCreate',
  /**
   * 入库单查询
   */
  inboundOrderListQuery = 'inboundOrderListQuery',
  /**
   * 入库单提交审核
   */
  inboundOrderSubmitReview = 'inboundOrderSubmitReview',
  /**
   * 入库单审核
   */
  inboundOrderReview = 'inboundOrderReview',
}
//#endregion

//#region 仓储模块(快递签收)
export const enum TrackEvent {
  /**
   * 快递签收
   */
  expressSignature = 'expressSignature',
  /**
   * 快递签收查询
   */
  expressSignatureListQuery = 'expressSignatureListQuery',
}
//#endregion

//#region 仓储模块(短语音合成服务)
export const enum TrackEvent {
  /**
   * 短语音合成服务
   */
  tts = 'tts',
}
//#endregion

//#region 仓储模块(仓库发货)
export const enum TrackEvent {
  /**
   * 仓库订单查询
   */
  warehouseOrderListQuery = 'warehouseOrderListQuery',
  /**
   * 批量重新配货
   */
  batchRestock = 'batchRestock',
  /**
   * 批量指派
   */
  batchAssign = 'batchAssign',
  /**
   * 批量生成爆款
   */
  hotBatchCreate = 'hotBatchCreate',
  /**
   * 批量取消爆款
   */
  hotBatchCancel = 'hotBatchCancel',
  /**
   * 爆款批量生成配货任务
   */
  hotBatchCreateDistributionTask = 'hotBatchCreateDistributionTask',
  /**
   * 爆款批量生成发货任务
   */
  hotBatchCreateDeliverTask = 'hotBatchCreateDeliverTask',
  /**
   * 爆款批量重新配货
   */
  hotBtachRestock = 'hotBtachRestock',
  /**
   * 爆款批量指派
   */
  hotBatchAssign = 'hotBatchAssign',
  /**
   * 发货任务查询
   */
  deliverTaskListQuery = 'deliverTaskListQuery',
}
//#endregion

//#region 仓储模块(出库)
export const enum TrackEvent {
  /**
   * 完成配货
   */
  distributionComplete = 'distributionComplete',
  /**
   * 完成分拣
   */
  sortingComplete = 'sortingComplete',
  /**
   * 完成出库
   */
  outboundComplete = 'outboundComplete',
  /**
   * 称重出库
   */
  weightOutbound = 'weightOutbound',
  /**
   * 手工出库单查询
   */
  manualOutboundOrderListQuery = 'manualOutboundOrderListQuery',
  /**
   * 新增手工出库单
   */
  manualOutboundOrderCreate = 'manualOutboundOrderCreate',
  /**
   * 编辑手工出库单
   */
  manualOutboundOrderUpdate = 'manualOutboundOrderUpdate',
  /**
   * 删除手工出库单
   */
  manualOutboundOrderRemove = 'manualOutboundOrderRemove',
  /**
   * 手工出库单导出
   */
  manualOutboundOrderExport = 'manualOutboundOrderExport',
  /**
   * 拼接code时错误code记录
   */
  badSkuCodeError = 'badSkuCodeError',
}
//#endregion

//#region 仓储模块(库存盘点)
export const enum TrackEvent {
  /**
   * 库存盘点查询
   */
  stockTakingListQuery = 'stockTakingListQuery',
  /**
   * 新增库存盘点
   */
  stockTakingCreate = 'stockTakingCreate',
  /**
   * 编辑库存盘点
   */
  stockTakingUpdate = 'stockTakingUpdate',
  /**
   * 作废库存盘点
   */
  stockTakingInvalidate = 'stockTakingInvalidate',
}
//#endregion

//#region 仓储模块(修改仓位)
export const enum TrackEvent {
  /**
   * 手动修改仓位
   */
  positionManualUpdate = 'positionManualUpdate',
  /**
   * 任务修改仓位
   */
  positionTaskUpdate = 'positionTaskUpdate',
  /**
   * 任务管理查询
   */
  taskManagementListQuery = 'taskManagementListQuery',
  /**
   * 任务管理删除
   */
  taskManagementRemove = 'taskManagementRemove',
  /**
   * 任务管理批量删除
   */
  taskManagementBatchRemove = 'taskManagementBatchRemove',
  /**
   * 任务管理重新处理
   */
  taskManagementRehandling = 'taskManagementRehandling',
  /**
   * 任务管理批量重新处理
   */
  taskManagementBatchRehandling = 'taskManagementBatchRehandling',
}
//#endregion

//#region 仓储模块(调拨)
export const enum TrackEvent {
  /**
   * 调拨列表查询
   */
  transferListQuery = 'transferListQuery',
  /**
   * 新增调拨
   */
  transferCreate = 'transferCreate',
  /**
   * 编辑调拨
   */
  transferUpdate = 'transferUpdate',
  /**
   * 作废调拨
   */
  transferInvalidate = 'transferInvalidate',
  /**
   * 批量作废调拨
   */
  transferBatchInvalidate = 'transferBatchInvalidate',
}
//#endregion

//#region 仓储模块(日志)
export const enum TrackEvent {
  /**
   * 入库日志查询
   */
  inboundLogListQuery = 'inboundLogListQuery',
  /**
   * 订单出库日志查询
   */
  outboundLogListQuery = 'outboundLogListQuery',
  /**
   * 订单出库日志导出
   */
  outboundLogExport = 'outboundLogExport',
  /**
   * 商品出库日志查询
   */
  outboundSkuLogListQuery = 'outboundSkuLogListQuery',
  /**
   * 商品出库日志导出
   */
  outboundSkuLogExport = 'outboundSkuLogExport',
}
//#endregion

//#region 物流模块
//#region 物流授权
export const enum TrackEvent {
  /**
   * 物流授权
   */
  logisticsAuthorize = 'logisticsAuthorize',
  /**
   * 物流取消授权
   */
  logisticsUnauthorize = 'logisticsUnauthorize',
  /**
   * 复制自发物流公司
   */
  logisticsCompanyCopy = 'logisticsCompanyCopy',
  /**
   * 编辑自发物流公司
   */
  logisticsCompanyUpdate = 'logisticsCompanyUpdate',
  /**
   * 创建自定义物流
   */
  logisticsCreateCustom = 'logisticsCreateCustom',
  /**
   * 复制自定义物流
   */
  logisticsCopyCustom = 'logisticsCopyCustom',
  /**
   * 编辑自定义物流
   */
  logisticsUpdateCustom = 'logisticsUpdateCustom',
  /**
   * 物流渠道停用
   */
  logisticsDisableChannel = 'logisticsDisableChannel',
  /**
   * 删除复制的物流渠道
   */
  logisticsRemoveCopiedChannel = 'logisticsRemoveCopiedChannel',
  /**
   * 导出订单物流跟踪信息
   */
  logisticsExportTrackInformation = 'logisticsExportTrackInformation',
  /**
   * 查询订单物流跟踪信息
   */
  logisticsTrackInformation = 'logisticsTrackInformation',
  /**
   * 新建自定义渠道
   */
  logisticsCreateCustomChannel = 'logisticsCreateCustomChannel',
}
//#endregion
//#endregion

//#region 设置模块
export const enum TrackEvent {}
//#endregion

//#region PDA模块
export const enum TrackEvent {
  /**
   * 隐藏键盘
   */
  hideKeyboard = 'hideKeyboard',
  /**
   * 开启键盘
   */
  showKeyboard = 'showKeyboard',
  /**
   * 仓库选择
   */
  warehouseSelect = 'warehouseSelect',
  /**
   * 快递签收扫码
   */
  expressSignatureScan = 'expressSignatureScan',
  /**
   * 入库签收扫物流单号获取待签收采购单
   */
  inboundSignatureListQueryByLogisticsOrder = 'inboundSignatureListQueryByLogisticsOrder',
  /**
   * 入库签收扫采购单号获取待签收采购单
   */
  inboundSignatureListQueryByPurchaseNumber = 'inboundSignatureListQueryByPurchaseNumber',
  /**
   * 入库签收不良品登记
   */
  inboundSignatureDefectivesRegiste = 'inboundSignatureDefectivesRegiste',
  /**
   * 入库签收任务码绑定
   */
  inboundSignatureTaskcodeBind = 'inboundSignatureTaskcodeBind',
  /**
   * 入库上架扫码获取任务
   */
  inboundStackingTaskQuery = 'inboundStackingTaskQuery',
  /**
   * 入库上架列表刷新
   */
  inboundStackingTaskRefetch = 'inboundStackingTaskRefetch',
  /**
   * 入库上架快速调整仓位
   */
  inboundStackingPositionChange = 'inboundStackingPositionChange',
  /**
   * 入库上架扫仓位完成上架
   */
  inboundStackingFinish = 'inboundStackingFinish',
  /**
   * 调拨入库扫码获取任务
   */
  transferInboundTaskQuery = 'transferInboundTaskQuery',
  /**
   * 调拨入库列表刷新
   */
  transferInboundTaskRefetch = 'transferInboundTaskRefetch',
  /**
   * 调拨入库快速调整仓位
   */
  transferInboundPositionChange = 'transferInboundPositionChange',
  /**
   * 调拨入库扫仓位完成上架
   */
  transferInboundFinish = 'transferInboundFinish',
  /**
   * 单品配货获取任务
   */
  singlePickingTaskQuery = 'singlePickingTaskQuery',
  /**
   * 单品配货修改配货数量
   */
  singlePickingQuantityChange = 'singlePickingQuantityChange',
  /**
   * 单品配货配货异常
   */
  singlePickingException = 'singlePickingException',
  /**
   * 单品配货输入框扫SKU
   */
  singlePickingSKUScan = 'singlePickingSKUScan',
  /**
   * 单品配货数量修正
   */
  singlePickingQuantityFix = 'singlePickingQuantityFix',
  /**
   * 多品配货获取任务
   */
  multiPickingTaskQuery = 'multiPickingTaskQuery',
  /**
   * 多品配货修改配货数量
   */
  multiPickingQuantityChange = 'multiPickingQuantityChange',
  /**
   * 多品配货配货异常
   */
  multiPickingException = 'multiPickingException',
  /**
   * 多品配货输入框扫SKU
   */
  multiPickingSKUScan = 'multiPickingSKUScan',
  /**
   * 多品配货数量修正
   */
  multiPickingQuantityFix = 'multiPickingQuantityFix',
  /**
   * 发货任务登记输入框扫码 / 输入
   */
  deliveryTaskRegisterScan = 'deliveryTaskRegisterScan',
  /**
   * 爆款配货获取任务
   */
  hotPickingTaskQuery = 'hotPickingTaskQuery',
  /**
   * 爆款配货修改配货数量
   */
  hotPickingQuantityChange = 'hotPickingQuantityChange',
  /**
   * 爆款配货配货异常
   */
  hotPickingException = 'hotPickingException',
  /**
   * 爆款配货输入框扫SKU
   */
  hotPickingSKUScan = 'hotPickingSKUScan',
  /**
   * 爆款配货数量修正
   */
  hotPickingQuantityFix = 'hotPickingQuantityFix',
  /**
   * 调拨出库获取任务
   */
  transferOutboundTaskQuery = 'transferOutboundTaskQuery',
  /**
   * 调拨出库扫调拨单号获取任务
   */
  transferOutboundTaskQueryByTransferNumber = 'transferOutboundTaskQueryByTransferNumber',
  /**
   * 调拨出库修改验货数量
   */
  transferOutboundQuantityChange = 'transferOutboundQuantityChange',
  /**
   * 调拨出库输入框扫SKU
   */
  transferOutboundSKUScan = 'transferOutboundSKUScan',
  /**
   * 调拨出库出库异常
   */
  transferOutboundException = 'transferOutboundException',
  /**
   * 手动修改仓位输入框扫仓位完成
   */
  manualPositionFinish = 'manualPositionFinish',
  /**
   * 任务修改仓位获取任务
   */
  taskPositionTaskQuery = 'taskPositionTaskQuery',
  /**
   * 任务修改仓位输入框扫仓位完成
   */
  taskPositionFinish = 'taskPositionFinish',
  /**
   * 手动盘点库存量自动填充实盘数量
   */
  manualInventoryFillRealQuantitySet = 'manualInventoryFillRealQuantitySet',
  /**
   * 手动盘点扫仓位添加待盘点
   */
  manualInventoryAddByPosition = 'manualInventoryAddByPosition',
  /**
   * 手动盘点扫SKU添加待盘点
   */
  manualInventoryAddBySKU = 'manualInventoryAddBySKU',
  /**
   * 手动盘点无库存盘点
   */
  manualInventoryNoStockInventory = 'manualInventoryNoStockInventory',
  /**
   * 手动盘点扫仓位完成
   */
  manualInventoryFinishByPosition = 'manualInventoryFinishByPosition',
  /**
   * 任务盘点获取任务
   */
  taskInventoryTaskQuery = 'taskInventoryTaskQuery',
  /**
   * 任务盘点库存量自动填充实盘数量
   */
  taskInventoryFillRealQuantitySet = 'taskInventoryFillRealQuantitySet',
  /**
   * 任务盘点无库存盘点
   */
  taskInventoryNoStockInventory = 'taskInventoryNoStockInventory',
  /**
   * 任务盘点扫仓位完成
   */
  taskInventoryFinishByPosition = 'taskInventoryFinishByPosition',
  /**
   * 库内上架扫仓位完成上架
   */
  warehouseStackingFinish = 'warehouseStackingFinish',
  /**
   * 退包上架获取任务
   */
  returnPackageStackingTaskQuery = 'returnPackageStackingTaskQuery',
  /**
   * 退包上架快速调整仓位
   */
  returnPackageStackingPositionChange = 'returnPackageStackingPositionChange',
  /**
   * 退包上架扫仓位完成上架
   */
  returnPackageStackingFinish = 'returnPackageStackingFinish',
  /**
   * 商品信息查询扫商品编号查询
   */
  skuInfoQueryBySku = 'skuInfoQueryBySku',
  /**
   * 商品信息查询商品名称查询
   */
  skuInfoQueryByTitle = 'skuInfoQueryByTitle',
  /**
   * 商品信息查询扫仓位查询
   */
  skuInfoQueryByPosition = 'skuInfoQueryByPosition',
}
//#endregion
