import { useRef } from 'react';

// 点击次数
const CLICK_THRESHOLD = 10;
// 点击间隔
const CLICK_INTERVAL = 1_000;

/**
 * 连续点击 10 次，后加载 eruda 调试工具
 */
export const useEruda = () => {
  const clickCount = useRef(0);
  const clickTime = useRef(0);

  const initEruda = async () => {
    const eruda = await import('eruda');
    eruda.default.init();
  };

  const clickHandler = () => {
    clickCount.current += 1;
    const now = Date.now();

    if (now - clickTime.current < CLICK_INTERVAL) {
      if (clickCount.current >= CLICK_THRESHOLD) {
        initEruda();
      }
    } else {
      clickCount.current = 0;
    }
    clickTime.current = now;
  };

  return {
    clickHandler,
  };
};
