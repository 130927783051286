export class LfqService {
  init(
    trackId: string,
    data: {
      user_id?: string | number;
      send_page_view?: boolean;
    },
  ) {
    try {
      window.lfq?.('init', trackId, data);
    } catch (error) {
      Promise.reject(error);
    }
  }

  track(event: string, data?: unknown) {
    try {
      window.lfq?.('track', event, data);
    } catch (error) {
      Promise.reject(error);
    }
  }
}

export const lfqService = new LfqService();
