import { BooleanNumber, type PrimaryKey } from '@erp-mobile/types';

export interface SyncPositionData {
  /**
   * sku id
   */
  sku_id: PrimaryKey;
  /**
   * 拓展属性编码
   */
  custom_text_code: string;
  /**
   * 拓展属性
   */
  custom_text: string;
  /**
   * 商品SKU
   */
  sku_code: string;
}

/**
 * 修改仓位 - 入库类型
 */
export enum ChangePositionInboundType {
  入库上架 = 1,
  退包上架 = 2,
  调拨入库 = 5,
}
export interface ChangePosition {
  sku_code: string;
  sku_id: PrimaryKey;
  warehouse_shelf_positions_id_old: PrimaryKey;
  warehouse_shelf_positions_id: PrimaryKey;
  warehouse_id: PrimaryKey;
  /**
   * 拓展属性编码
   */
  custom_text_code?: string;
  /**
   * 拓展属性
   */
  custom_text?: string;
  /**
   * 是否同步仓位
   * 0: 不同步
   * 1: 同步
   */
  is_sync_position?: BooleanNumber;
  /**
   * 同步仓位数据
   */
  sync_position_data?: SyncPositionData[];
  /**
   * 修改仓位 - 入库类型
   */
  inbound_type: ChangePositionInboundType;
  wms_inbound_action_item_id?: PrimaryKey
}

export interface FinishedInbound {
  id: PrimaryKey;
  wait_received_num?: number;
  this_received_num?: number;
  bad_received_num?: number;
  task_code: string;
  'User-Agent': string;
  position_code: string;
}

export enum InboundStackingTaskType {
  入库上架 = 1,
  退包上架 = 2,
}

/**
 * 类型转换
 *
 * 用于区分【退包上架】和【入库上架】
 */
export const taskTypeToInboundType = (taskType: InboundStackingTaskType) => {
  switch (taskType) {
    case InboundStackingTaskType.入库上架:
      return ChangePositionInboundType.入库上架;
    case InboundStackingTaskType.退包上架:
      return ChangePositionInboundType.退包上架;
  }
};
