import type { PrimaryKey } from '@erp-mobile/types';
import { useMutation } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useSkuWarehouseId } from '../manual-inventory';
import {
  WarehouseOptionIsDataPermissions,
  WarehouseOptionIsPublish,
  useWarehouseOptions,
} from '../warehouse-setting';
import {
  isUnbind,
  type CompleteJobPayload,
  type GetModifyJobPayload,
  type ModifyJobItem,
} from './modify-job.dto';
import { modifyJobService } from './modify-job.service';

/**
 *  将 status 为 已解绑 的 sku 置底
 */
const sortModifyJobList = (list: ModifyJobItem[]) => {
  const unBindList: ModifyJobItem[] = [];
  const commonList: ModifyJobItem[] = [];
  for (const item of list) {
    if (isUnbind(item)) {
      unBindList.push(item);
    } else {
      commonList.push(item);
    }
  }

  return [...commonList, ...unBindList];
};

export const useGetJob = () => {
  return useMutation({
    mutationFn: async (params: GetModifyJobPayload) => {
      const list = await modifyJobService.getModifyJob(params);
      return sortModifyJobList(list);
    },
  });
};

export const useMutationCompleteJob = () => {
  return useMutation({
    mutationFn: async (data: CompleteJobPayload) => {
      return await modifyJobService.doModifyJob(data);
    },
  });
};

export const useJobWarehouseId = () => {
  const { warehouseId, saveWarehouseId, setWarehouseId } = useSkuWarehouseId();
  const { options } = useWarehouseOptions({
    is_data_permissions: WarehouseOptionIsDataPermissions.按数据权限展示,
    is_publish: WarehouseOptionIsPublish.启用,
  });

  useEffect(() => {
    if (warehouseId && options) {
      const warehouse = options?.find(
        (i) => warehouseId && i.id === +warehouseId,
      );
      // 仓库不存在。或取消了权限，需要清空
      if (!warehouse) {
        setWarehouseId(void 0);
      }
    }
  }, [warehouseId, options]);

  return {
    warehouseId,
    saveWarehouseId,
    options,
  };
};

/**
 * 删除任务
 */
export const useRemoveJob = () => {
  return useMutation({
    mutationFn: async (id: PrimaryKey) => {
      return await modifyJobService.removeJob(id);
    },
  });
};

/**
 * 绑定
 */
export const useBindSku = () => {
  return useMutation({
    mutationFn: async (id: PrimaryKey) => {
      return await modifyJobService.bindSku(id);
    },
  });
};
