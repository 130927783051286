import 'core-js/stable';

import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import './smoothscroll-polyfill';

import './styles.less';

import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import App from './app/app';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
);

// eslint-disable-next-line no-console
console.log(
  `%cVERSION%c${process.env.NX_PUBLIC_APP_VERSION || 'development'}`,
  `
  border-radius: 4px 0 0 4px;
  background: #11ce8c;
  padding: 4px 8px;
  color: #fff;
  font-size: 24px;
  font-family: monospace;
  `,
  `
  border-radius: 0 4px 4px 0;
  background: #3d68ff;
  padding: 4px 8px;
  color: #fff;
  font-size: 24px;
  font-family: monospace;
  `,
);
