import { useVersion } from '@/features/workspace/version-notification';
import { NoticeBar, Space } from 'antd-mobile';

const currentVersion = process.env.NX_PUBLIC_APP_VERSION || 'development';

export const VersionNotification = () => {
  const { data: latestVersion } = useVersion();

  if (currentVersion === latestVersion || !latestVersion) {
    return null;
  }

  return (
    <div>
      <NoticeBar
        extra={
          <Space>
            <span
              onClick={() => {
                window.location.reload();
              }}
            >
              点击刷新
            </span>
          </Space>
        }
        content={'ERP已发布最新版本，请刷新浏览器获取最新版本。'}
        color="info"
      />
    </div>
  );
};
