import type { AxiosInstance } from 'axios';
import { lfqService } from './lfq.service';
import { TrackEvent } from './track-event';

export function enableXhrTiming(axios: AxiosInstance) {
  if (window.performance == null) {
    console.warn('缺少 "PerformanceObserver" 对象，跳过接口耗时监控');
    return;
  }

  axios.interceptors.response.use((res) => {
    const request: XMLHttpRequest = res.request;
    const baseUrl = new URL('api/', document.baseURI);

    if (request.responseURL.startsWith(baseUrl.toString()) == false) {
      // 只会监听后端的接口请求，后端接口请求都是以 /api/ 开头
      return res;
    }

    const entries = window.performance.getEntriesByName(
      request.responseURL,
    ) as PerformanceResourceTiming[];

    const entry = entries
      .reverse()
      .find((item) => item.initiatorType === 'xmlhttprequest');

    if (!entry) {
      return res;
    }

    const {
      initiatorType,
      startTime,
      fetchStart,
      domainLookupStart,
      domainLookupEnd,
      connectStart,
      connectEnd,
      secureConnectionStart,
      requestStart,
      responseStart,
      responseEnd,
      transferSize,
      duration,
    } = entry as PerformanceResourceTiming;

    let url: URL | undefined;

    try {
      url = new URL(request.responseURL);
    } catch (error) {
      console.error(error);
    }

    lfqService.track(TrackEvent.xhrTimings, {
      path: url?.pathname,
      requestId: res.headers['x-request-id'],
      phases: {
        dns: toFixed(domainLookupEnd - domainLookupStart),
        tcp: toFixed(connectEnd - connectStart),
        tls: toFixed(connectEnd - secureConnectionStart),
        request: toFixed(responseStart - requestStart),
        download: toFixed(responseEnd - responseStart),
        duration: toFixed(duration),
      },
      startTime: toFixed(startTime),
      fetchStart: toFixed(fetchStart),
      domainLookupStart: toFixed(domainLookupStart),
      domainLookupEnd: toFixed(domainLookupEnd),
      connectStart: toFixed(connectStart),
      connectEnd: toFixed(connectEnd),
      secureConnectionStart: toFixed(secureConnectionStart),
      requestStart: toFixed(requestStart),
      responseStart: toFixed(responseStart),
      responseEnd: toFixed(responseEnd),
      transferSize,
      initiatorType,
    } satisfies Timings);

    return res;
  });
}

interface Timings extends Partial<PerformanceResourceTiming> {
  path?: string;

  requestId?: string;

  phases: Phases;
}

interface Phases {
  dns: number;
  tcp: number;
  tls: number;
  request: number;
  download: number;
  duration: number;
}

function toFixed(value: number) {
  return +value.toFixed(2);
}
