import { useErrorHandler, type RouteConfigHandle } from '@erp-mobile/core';
import { TrackEvent, lfq } from '@erp/lfq';
import { useEffect } from 'react';
import { Outlet, useLocation, useMatches } from 'react-router-dom';

/**
 * 限制页面宽度，居中显示
 */
export const MobilePage = () => {
  useErrorHandler();

  const matchedRoutes = useMatches();
  const location = useLocation();
  useEffect(() => {
    lfq.track(TrackEvent.pageView);
    lfq.track(TrackEvent.location, location);
  }, [location]);

  useEffect(() => {
    const routesWithName = matchedRoutes.filter(
      (item) => (item.handle as RouteConfigHandle | undefined)?.name != null,
    );
    const route = routesWithName[routesWithName.length - 1];
    const title =
      (route?.handle as RouteConfigHandle | undefined)?.name ?? 'ERP Mobile';

    document.title = title;
  }, [matchedRoutes]);

  return <Outlet></Outlet>;
};
