import type { PrimaryKey } from '@erp-mobile/types';

export const MODIFYJOB_UNBIND_STATUS = '已解绑';
export const isUnbind = (job: ModifyJobItem) =>
  job.status === MODIFYJOB_UNBIND_STATUS;

export interface ModifyJobItem {
  id: PrimaryKey;
  /**
   * 缩略图
   */
  cover: string;
  /**
   * sku code
   */
  sku_code: string;
  /**
   * sku中文名称
   */
  sku_title: string;
  /**
   * 仓库名称
   */
  warehouse_title: string;
  /**
   * 原仓位
   */
  warehouse_position_code: string;
  /**
   * 新仓位
   */
  warehouse_position_code_new: string;
  /**
   * 库存
   */
  stock: number;
  /**
   * 状态
   */
  status: string;
  /**
   * 是否换标 0 否 1 是
   */
  is_label: number;
  /**
   * 目标sku
   */
  target_sku_code: string;
  /**
   * 已配货数量
   */
  allocated_quantity: number;
}

export interface GetModifyJobPayload {
  warehouse_id: string;
}

// 完成任务
export interface CompleteJobPayload {
  id: PrimaryKey;
  /**
   * 仓库ID
   */
  warehouse_id: number;
  /**
   * SKU
   */
  sku_code: string;
  /**
   * 仓位
   */
  position_code: string;
}

export interface CompleteJobResp {
  code?: number;
}
